import '@polymer/paper-fab/paper-fab';
import '@polymer/paper-icon-button/paper-icon-button';
import { connect, withFit } from 'fit-html';
import { html } from 'lit-html';
import { createSelector } from 'reselect';
import toastr from "toastr";

toastr.options = {
    preventDuplicates: true,
   
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-bottom-full-width",

    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

import { installPlaybackMaster } from '../actions/party-data';
import { togglePlaybackStart } from '../actions/playback-spotify';
import { removeTrackAction, requestSetVoteAction as setVoteAction } from '../actions/queue';
import srcsetImg from '../components/srcset-img';
import {
    hasOtherPlaybackMasterSelector,
    isPartyOwnerSelector,
    isPlaybackMasterSelector,
    playbackMasterSelector,
} from '../selectors/party';
import {
    artistJoinerFactory,
    currentTrackSelector,
    singleMetadataSelector,
    singleTrackSelector,
    tracksEqual,
    voteStringGeneratorFactory,
} from '../selectors/track';
import { hasConnectedSpotifyAccountSelector } from '../selectors/users';
import { Metadata, State, Track, TrackReference } from '../state';
import sharedStyles from '../util/shared-styles';

interface PartyTrackProps {
    artistName: string | null;
    enablePlayButton: boolean;
    hasConnectedSpotifyAccount: boolean;
    hasVoted: boolean;
    isOwner: boolean;
    isMusicPlaying: boolean;
    isPlayingTrack: boolean;
    metadata: Metadata | null;
    showRemoveButton: boolean;
    showTakeoverButton: boolean;
    togglingPlayback: boolean;
    track: Track | null;
    voteString: string;
    previewvalue: boolean|string;
}

interface PartyTrackDispatch {
    removeTrack: (ref: TrackReference) => void;
    setVote: (ref: TrackReference, isCurrentTrack: boolean) => void;
    takeOverPlayback: () => void;
    togglePlayPause: () => void;
}

interface PartyTrackOwnProps {
    playing: boolean;
    trackid: string;
}

type PartyTrackRenderProps = PartyTrackProps & PartyTrackOwnProps & PartyTrackDispatch;

/* tslint:disable:max-line-length */
const LikeButtonIcon = (props: PartyTrackRenderProps): string => {
    if (!props.track) {
        return '';
    }

    if (props.hasVoted) {
        return 'theparcheplayer:favorite';
    } else if (props.track.vote_count > 0 || props.track.is_fallback) {
        return 'theparcheplayer:favorite-border';
    } else {
        return 'theparcheplayer:add';
    }
};







const PlayButton = (props: PartyTrackRenderProps) => {
    if (props.isPlayingTrack) {
        return html`
            ${props.isOwner && props.track
                ? html`
                    <paper-icon-button icon="theparcheplayer:skip-next"
                                       @click=${() => props.removeTrack(props.track!.reference)}
                                       title="Skip ${props.metadata ? props.metadata.name : 'Cargando...'}">
                    </paper-icon-button>
                `
                : null}
            <div class="fab-spinner">
                <paper-spinner-lite .active=${props.togglingPlayback}></paper-spinner-lite>
                <paper-fab mini
                          icon=${props.isMusicPlaying ? 'theparcheplayer:pause' : 'theparcheplayer:play-arrow'}
                           @click=${props.togglePlayPause}
                           .disabled=${!props.enablePlayButton}>
                </paper-fab>
            </div>
        `;
    } else {
        return props.track
            ? html`
            <paper-icon-button icon="${LikeButtonIcon(props)}"
            @click=${() => {
                props.setVote(props.track!.reference, !props.hasVoted);
                toastr.error('Voto registrado'); // Agrega esto
            }}
            title="${(props.hasVoted ? "Quitar votos " : "Vota por ") + (props.metadata ? props.metadata.name : 'Cargando...')}">
</paper-icon-button>
            `
            : null;
    }
};



//////console.log("7 party")

let currentAudio2: HTMLAudioElement | null = null;
let isPlaying2 = true;
const maxLength = 30; // Establece la longitud máxima de caracteres aquí



const pauseAudio = () => {
  if (currentAudio2) {
    currentAudio2.pause();
    //isPlaying2 = false;
  } else {
    //////console.log("No audio to pause.");
    
  }
};

const playAudio = (previewUrl: string | null) => {
  if (!previewUrl) {
    //////console.log("No hay URL de vista previa disponible");
    return;
  }

  if (currentAudio2) {
    
    console.log('El audio ya se está reproduciendo');
    //////console.log('El audio se está reproduciendo: ', isPlaying2);
     currentAudio2.pause();
    currentAudio2.currentTime = 0;

  }

  currentAudio2 = new Audio(previewUrl);

  // Agrega eventos para verificar si el audio está siendo cargado correctamente
  //currentAudio2.addEventListener('canplay', () => //////console.log('El audio está listo para ser reproducido'));
  //currentAudio2.addEventListener('canplaythrough', () => //////console.log('El audio ha cargado completamente y está listo para ser reproducido'));

  // Inicia la reproducción del audio
  //////console.log('Iniciando la reproducción del audio');
  toastr.success('Play sound preview'); 
 console.log('El audio se está reproduciendo22: ', isPlaying2);
  currentAudio2.play();
  isPlaying2 = true;

  // Agrega un evento para actualizar el estado de //isPlaying2 cuando el audio finalice la reproducción o se pause
  currentAudio2.addEventListener('ended', () => {
    //////console.log('El audio ha terminado la reproducción');
    isPlaying2 = false;
  });
  currentAudio2.addEventListener('pause', () => {
    
   // Esto cerrará todos los toasts abiertos
     // Esto mostrará el nuevo toast
     toastr.success('Play sound preview');
    console.log('El audio se ha pausado');
    isPlaying2 = false;
  });
};


const player2 = {
  previewUrls: [/* ... Lista de URLs de vista previa ... */],
  currentIndex: 0,
  currentUrl: null as string | null,
};

const togglePlayPause2 = (previewUrl: string | null) => {
  if (!previewUrl) {
    //////console.log("No hay URL de vista previa disponible");
    return;
  }

  if (player2.currentUrl === previewUrl) {
    // Detener la reproducción actual
    pauseAudio();

    // Cambiar al siguiente elemento en la lista
    player2.currentIndex = (player2.currentIndex + 1) % player2.previewUrls.length;
    previewUrl = player2.previewUrls[player2.currentIndex];

    // Comenzar a reproducir el siguiente elemento
    playAudio(previewUrl);
    player2.currentUrl = previewUrl;
  } else {
    playAudio(previewUrl);
    player2.currentUrl = previewUrl;
  }
};


export const PartyTrack = (props: PartyTrackRenderProps) => {
//     //////console.log("Nombre del artista:", props.togglingPlayback); // Agregamos el //////console.log aquí para ver el nombre del artista
//     //////console.log("Nombre del artista:", props.artistName); 
//     //////console.log("Nombre del metadata:", props.metadata);



    return html`
    ${sharedStyles}
    <style>
        :host {
            box-sizing: content-box;
            padding: 5px 16px;
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        :host([playing]) {
            background-color: #22262b;
            padding: 13px 16px;
        }

        :host([playing]) + :host {
            padding-top: 13px;
        }

        :host([playing]) img {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        }

        :host([playing]) .metadata-wrapper {
            margin-right: 20px;
        }


        img, .empty {
            background: rgba(0, 0, 0, 0.2);
            flex-shrink: 0;
            height: 54px;
            margin-right: 15px;
            width: 54px;
        }

        .metadata-wrapper {
            margin-top: 2px;
            overflow: hidden;
        }

        h2 {
            margin: 0;
            font-weight: lighter;
            font-size: 15px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        aside {
            margin: 2px 0;
            font-weight: 300;
            font-size: 13px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .dot {
            margin: 0 4px;
        }

        .icon-wrapper {
            display: flex;
            margin-left: auto;
            flex-basis: 40px;
        }

        paper-fab {
            color: white;
            background-color: var(--primary-color);
        }

        paper-fab[disabled] {
            opacity: 0.7;
        }

        paper-icon-button {
            margin-left: 5px;
            padding: 6px;
        }

        .fab-spinner {
            margin-left: 5px;
            position: relative;
        }

        .fab-spinner paper-spinner-lite {
            --paper-spinner-stroke-width: 2px;
            --offset: calc(-1 * var(--paper-spinner-stroke-width));
            --size: calc(40px + 2 * var(--paper-spinner-stroke-width));

            position: absolute;
            top: var(--offset);
            left: var(--offset);
            width: var(--size);
            height: var(--size);
            --paper-spinner-color: white;
            pointer-events: none;
        }
.cover-container {
    position: relative;
    display: inline-block;
}

.button-container {
    position: absolute;
    top: 0;
    left: 7px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    opacity: 0.7;
    
}


    </style>


<div class="cover-container">
    ${props.metadata
        ? srcsetImg(props.metadata.cover, '54px')
        : html``}
    <div class="button-container" style="${(!props.metadata || !props.metadata.previewUrl || !props.previewvalue) ? 'display: none;' : ''}">
        <paper-icon-button
            class="play-pause-button"
            icon='theparcheicon21:clear2'
            @click=${() => togglePlayPause2(props.metadata ? props.metadata.previewUrl : '')}
        ></paper-icon-button>
    </div>
</div>


    <div class="metadata-wrapper">




               <h2>
            ${props.metadata
                ? props.metadata.name.length > maxLength
                    ? props.metadata.name.substring(0, maxLength) + " ..."
                    : props.metadata.name
                : 'Cargando...'}
        </h2>
        ${props.artistName
            ? html`
                <aside>
                    <span>${props.voteString}</span>
                     <span class="dot">&middot;</span>
                                  <a>
                    ${props.artistName.length > maxLength
                        ? props.artistName.substring(0, maxLength) + " ..."
                        : props.artistName}
                </a>
                   
                    

                </aside>
            `
            : null
        }
    </div>

    <div class="icon-wrapper">
            ${props.showTakeoverButton
            ? html`
                <paper-icon-button icon="theparcheplayer:download"
                                   @click=${props.takeOverPlayback}
                                   title="Transferir la reproducción al dispositivo actual">
                </paper-icon-button>
            `
            : null}
        ${props.showRemoveButton
            ? html`
                <paper-icon-button icon="theparcheplayer:clear"
                                   @click=${() => props.removeTrack(props.track!.reference)}
                                   title="Eliminar ${props.metadata ? props.metadata.name : 'Cargando...'} de la lista">
                </paper-icon-button>
            `
            : null}
        ${PlayButton(props)}
    </div>
    `;
};
/* tslint:enable */


const isCompatibleSelector = (s: State) => s.player.isCompatible;

/**
 * Computes whether the play fab is enabled.
 *
 * Enable it when
 * 1. we're Party Owner AND
 * 2. we're not toggling the playback right now AND
 * 3. we're either on a compatible device, or we're just controlling some other device AND
 * 4. we either have a Spotify account connected, or we're just controlling some other device.
 */
const enablePlayButtonSelector = createSelector(
    isPartyOwnerSelector,
    (s: State) => s.player.togglingPlayback,
    isCompatibleSelector,
    hasConnectedSpotifyAccountSelector,
    hasOtherPlaybackMasterSelector,
    (isOwner, isToggling, isCompatible, hasSptAccount, hasOtherMaster) =>
        isOwner &&
        !isToggling &&
        ((!isCompatible && hasOtherMaster) || isCompatible) &&
        ((!hasSptAccount && hasOtherMaster) || hasSptAccount),
);

export const createMapStateToPropsFactory = (
    trackSelector: (state: State, trackId: string) => Track | null,
) => {


    const hasVotesOrIsFallbackSelector = createSelector(trackSelector, track =>
        Boolean(track && (track.vote_count > 0 || track.is_fallback)),
    );

    /*
     * Since the selectors use component props, one for each instance must be created.
     */
    return () => {
        const artistJoiner = artistJoinerFactory();
        const isPlayingSelector = createSelector(
            currentTrackSelector,
            trackSelector,
            (currentTrack, track) => tracksEqual(currentTrack, track),
        );
        const showRemoveTrackButtonSelector = createSelector(
            isPartyOwnerSelector,
            hasVotesOrIsFallbackSelector,
            isPlayingSelector,
            (isOwner, hasVotesOrIsFallback, isPlaying) =>
                isOwner && hasVotesOrIsFallback && !isPlaying,
        );
        const showTakeoverButtonSelector = createSelector(
            isPartyOwnerSelector,
            isPlaybackMasterSelector,
            playbackMasterSelector,
            isPlayingSelector,
            isCompatibleSelector,
            hasConnectedSpotifyAccountSelector,
            (isOwner, isMaster, master, isPlaying, isCompatible, hasSptConnected) =>
                isPlaying && isOwner && !isMaster && !!master && isCompatible && hasSptConnected,
        );








        const voteStringGenerator = voteStringGeneratorFactory(trackSelector);

        return (state: State, ownProps: PartyTrackOwnProps): PartyTrackProps => {



//agrego esta constante para poder ver el valor de mostrarmenu (para ver el chat)
const previewvalue = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu2 : 'undefined';






    return {
        artistName: artistJoiner(state, ownProps.trackid),
        enablePlayButton: enablePlayButtonSelector(state),
        hasConnectedSpotifyAccount: hasConnectedSpotifyAccountSelector(state),
        hasVoted: !!state.party.userVotes && state.party.userVotes[ownProps.trackid] === true,
        isOwner: isPartyOwnerSelector(state),
        isMusicPlaying: !!state.party.currentParty && state.party.currentParty.playback.playing,
        isPlayingTrack: isPlayingSelector(state, ownProps.trackid),
        metadata: singleMetadataSelector(state, ownProps.trackid),
        showRemoveButton: showRemoveTrackButtonSelector(state, ownProps.trackid),
        showTakeoverButton: showTakeoverButtonSelector(state, ownProps.trackid),
        togglingPlayback: state.player.togglingPlayback,
        track: trackSelector(state, ownProps.trackid),
        voteString: voteStringGenerator(state, ownProps.trackid),
        previewvalue: previewvalue,
    };
};
    };
};

export const mapDispatchToProps: PartyTrackDispatch = {
    removeTrack: (ref: TrackReference) => removeTrackAction(ref, false),
    setVote: (ref: TrackReference, isCurrentTrack: boolean) => setVoteAction(ref, isCurrentTrack),
    takeOverPlayback: installPlaybackMaster,
    togglePlayPause: togglePlaybackStart,
};

export const PartyTrackElementBase = withFit<PartyTrackOwnProps, PartyTrackRenderProps>(
    PartyTrack,
    {
        playing: Boolean,
        trackid: String,
    },
)(HTMLElement);

const PartyTrackElement = connect(
    createMapStateToPropsFactory(singleTrackSelector),
    mapDispatchToProps,
)(PartyTrackElementBase);

customElements.define('party-track', PartyTrackElement);
