import { connect, withFit } from 'fit-html';
import { html } from 'lit-html';

import srcsetImg from '../components/srcset-img';
import {
    artistJoinerFactory,
    singleMetadataSelector,
    singleTrackSelector,
} from '../selectors/track';
import { Metadata, State, Track } from '../state';
import confetti from 'canvas-confetti';

interface TvTrackProps {
    artistName: string | null;
    metadata: Metadata | null;
    track: Track | null;
}

interface TvTrackOwnProps {
    trackid: string;
}
////console.log("3")
let lastAlertTime = 0;
let timeRemaining = 0;
let countdownTimer: any = null;

const TvTrack = (props: TvTrackProps) => {
    const currentTime = Date.now();
    const timeSinceLastAlert = currentTime - lastAlertTime;
    const alertInterval = 60000; // Intervalo de tiempo de espera de 1 minuto (en milisegundos)

    if (props.track && props.track.vote_count === 5 && timeSinceLastAlert > alertInterval) {

        //efecto
       var count = 200;
var defaults = {
  origin: { y: 0.7 }
};

function fire(particleRatio, opts) {
  confetti(Object.assign({}, defaults, opts, {
    particleCount: Math.floor(count * particleRatio)
  }));
}

fire(0.25, {
  spread: 26,
  startVelocity: 55,
});
fire(0.2, {
  spread: 60,
});
fire(0.35, {
  spread: 100,
  decay: 0.91,
  scalar: 0.8
});
fire(0.1, {
  spread: 120,
  startVelocity: 25,
  decay: 0.92,
  scalar: 1.2
});
fire(0.1, {
  spread: 120,
  startVelocity: 45,
});
        lastAlertTime = currentTime;
        timeRemaining = alertInterval;
        startCountdown();
    }

    return html`
  
    <style>
        :host {
            --max-width: 204px;
            min-width: var(--max-width);
            max-width: var(--max-width);
            display: block;
        }

        .cover {
            position: relative;
            display: flex;
            align-content: center;
            justify-content: center;
            width: var(--max-width);
            height: var(--max-width);
            box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.5);
        }

        img, .empty {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 10px;
        }

        .overlay {
            border-radius: 10px;
            position: absolute;
            z-index: 2;
            top: 0; bottom: 0;
            left: 0; right: 0;
            background: rgba(0, 0, 0, 0.6)
        }

        h2 {
            z-index: 3;
            font-size: 5.556vh;
            margin: auto;
            text-shadow: 0 0 8px #000;
        }

        h3 {
            font-size: 1.852vh;
            font-weight: normal;
            margin: 10px 0 0;
        }

        h4 {
            font-size: 1.111vh;
            font-weight: 100;
            margin: 0;
        }

        h2, h3, h4 {
            color: #fff;
        }

        h3, h4 {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
        }
    </style>

    <div class="cover">
        ${props.metadata
            ? srcsetImg(props.metadata.cover, '128px', props.metadata.name)
            : html`<div class="empty"></div>`}

        <div class="overlay"></div>
        <h2>${props.track ? props.track.vote_count : 0}</h2>
    </div>
    <h3>${props.metadata ? props.metadata.name : 'Loading...'}</h3>
    
 
    `;
};

function startCountdown() {
    clearInterval(countdownTimer);
    countdownTimer = setInterval(() => {
        if (timeRemaining > 0) {
            timeRemaining -= 1000;
            ////console.log(`Tiempo restante nueva alerta: ${formatTime(timeRemaining)}`);
        } else {
            clearInterval(countdownTimer);
        }
    }, 1000);
}

function formatTime(time: number) {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const mapStateToPropsFactory = () => {
    const artistJoiner = artistJoinerFactory();

    return (state: State, ownProps: TvTrackOwnProps): TvTrackProps => ({
        artistName: artistJoiner(state, ownProps.trackid),
        metadata: singleMetadataSelector(state, ownProps.trackid),
        track: singleTrackSelector(state, ownProps.trackid),
    });
};

const TvTrackBase = withFit<TvTrackOwnProps, TvTrackProps>(
    TvTrack,
    { trackid: String },
)(HTMLElement);

customElements.define(
    'tv-track',
    connect(mapStateToPropsFactory, {})(TvTrackBase),
);
