﻿import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-icon-button/paper-icon-button';
import { connect } from 'fit-html';
import { html } from 'lit-html';

import Swal from 'sweetalert2'
import QRCode from 'qrcode-generator';



import { logout, triggerOAuthLogin } from '../actions/auth';
import { handleLinkClick } from '../actions/nav';
import { toggleUserMenu } from '../actions/view-queue-drawer';
import { PartyViews } from '../routing';
import { isPartyOwnerSelector } from '../selectors/party';
import {
    queueRouteSelector,
    settingsRouteSelector,
    shareRouteSelector,
    tvRouteSelector,
} from '../selectors/routes';
import { currentUsernameSelector } from '../selectors/users';
import { State } from '../state';

import { loadLiveZillaScript } from '../state';



import theparcheplayerlogo from '../util/festify-logo';
import sharedStyles from '../util/shared-styles';

interface QueueDrawerProps {
    isOwner: boolean;
    queueRoute: string;
    settingsRoute: string;
    shareRoute: string;
    subView: PartyViews;
    tvRoute: string;
    userMenuOpen: boolean;
    username: string | null;
    namespotyuser: string; // <-- Add this line
    partyidd: string;
    shortidurl: boolean|string;
    //menuvalue: boolean|string;
    qrImage: string;

}

interface QueueDrawerDispatch {
    handleClick: (ev: Event) => void;
    enterAdmin: () => void;
    logout: () => void;
    toggleUserMenu: () => void;
}


//////console.log("5 queue")
const isActive = (isActive: boolean) => (isActive ? 'active' : '');

/* tslint:disable:max-line-length */
const QueueDrawer = (props: QueueDrawerProps & QueueDrawerDispatch) => {

    //////console.log(`The current username is ${props.username}`);

//////console.log(`lectura de variable com props: ${props.shortidurl}  ${props.namespotyuser}`);
//loadLiveZillaScript();

   

   

    return html`
    ${sharedStyles}
    <style>
        :host {
            color: rgba(255, 255, 255, 0.54);
            display: block;
            min-height: 100vh;
        }

        a,
        a:visited,
        .dropdown-content {
            color: rgba(0, 0, 0, 0.8);
            text-decoration: none;
            font-weight: bolder;
        }

        .hidable {
            transform-origin: 50% 0;
            transition: transform 0.3s ease, opacity 0.3s ease;
        }

        .hidable.hidden {
            opacity: 0;
            pointer-events: none;
            transform: scale3d(1, 1.1, 1);
        }

        /*
         * Header
         */
        header {
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url(https://source.unsplash.com/512x352/?concert) no-repeat center;
            background-size: cover;
            display: flex;
            flex-flow: column nowrap;
            height: 176px;
            position: relative;
        }

        header svg {
            margin: 16px 0 0 16px;
            height: 64px;
            width: 64px;
        }

        .user-menu {
            align-items: center;
            display: flex;
            font-weight: lighter;
            margin-top: auto;
            cursor: pointer;
        }

        .user-menu span {
            margin-left: 16px;
        }

        .user-menu paper-icon-button {
            margin-left: auto;
            transition: transform 0.3s ease;
        }

        .user-menu paper-icon-button.open {
            transform: rotate(-180deg);
        }

        /*
         * Menu
         */
        .menu {
            display: block;
            margin-top: 24px;
            position: absolute;
            width: 100%;
        }

        .menu a {
            display: block;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 24px 24px;
            cursor: pointer;
        }

        .menu iron-icon {
            color: rgba(0, 0, 0, .54);
            margin: 0 16px 2px 0;
        }

        .menu a.active,
        .menu a.active iron-icon {
            color: var(--primary-color);
        }
    </style>

    <header>
        ${theparcheplayerlogo}
     <div class="user-menu hidable ${props.username ? '' : 'hidden'}" @click=${props.toggleUserMenu}>
            <span>${props.username}</span>
            <paper-icon-button icon="theparcheplayer:expand-more"
                               class="${props.userMenuOpen ? 'open' : ''}"
                               title="Abrir menu">
            </paper-icon-button>
        </div>

    </header>

    <div class="menu hidable ${props.userMenuOpen ? 'hidden' : ''}" role="menu">
        <a
            href="${props.queueRoute}"
            class="${isActive(
                props.subView === PartyViews.Queue || props.subView === PartyViews.Search,
            )}"
            @click=${props.handleClick}
        >
            <iron-icon icon="theparcheicon21:icon-music"></iron-icon>
            lista
        </a>
                </a>
        ${props.isOwner
            ? html`
                  <a
                      href="${props.settingsRoute}"
                      class="${isActive(props.subView === PartyViews.Settings)}"
                      @click=${props.handleClick}
                  >
                      <iron-icon icon="theparcheicon21:icon-params"></iron-icon>
                      Configuraciones
                  </a>
              `
            : null}
        ${!props.isOwner
            ? html`
                  <a
                      href="#"
                      @click=${ev => {
                          ev.preventDefault();
                          props.enterAdmin();
                      }}
                  >
                      <iron-icon icon="theparcheplayer:settings-remote"></iron-icon>
                      Dj Mode
                  </a>
              `
            : null}

      <a href="https://player.microdelicos.co${props.tvRoute}" target="_blank">
            <iron-icon icon="theparcheplayer:tv"></iron-icon>

            Modo TV 
        </a>



        <a href="#" @click=${ev => {
            ev.preventDefault();
            Swal.fire({
                title: 'SCAN AND JOIN',
                text: 'Aquí puedes poner el mensaje que desees.',
                //icon: 'info',
                confirmButtonText: 'OK',
                showCloseButton: true,
                showConfirmButton: false,
                html: `<img id="qri"  src="${props.qrImage}" alt="Código QR">` // Aquí añades la imagen
            });
        }}>
            <iron-icon icon="theparcheicon21:icon-qr_code_2"></iron-icon>
            Party QR
        </a>
        









        <a href="/" @click=${props.handleClick}>
            <iron-icon icon="theparcheicon21:cerrrr"></iron-icon>
            Salir
        </a>
    </div>

       <div class="menu hidable ${props.userMenuOpen ? '' : 'hidden'}" role="menu">
        





 




        <a
            href="#"
            @click=${ev => {
                ev.preventDefault();
                props.logout();
            }}
        >
            <iron-icon icon="theparcheplayer:exit-to-app"></iron-icon>
            Logout
        </a>

    </div>  
 

`;
};

/* tslint:enable */


   ////////console.log(`The current username is ${props.username}`);



    const mapStateToProps = (state: State): QueueDrawerProps => {

   const partyidd =  state.party.currentParty ? state.party.currentParty.short_id : '';
    const username = currentUsernameSelector();
    

    ////////console.log("queue draws123",username);
    
    
    const namespotyuser = state.party.currentParty
    ? state.party.currentParty.name2
    : '';

     const shortidurl = state.party.currentParty
     ? state.party.currentParty.short_id22
     : '';

    

     


//datos para generar el qr
const url = "https://player.microdelicos.co/" + partyidd;
const qr = QRCode(0, 'L'); // Usa la función en lugar de la propiedad
qr.addData(url);
qr.make();

//https://www.npmjs.com/package/qrcode-generator
const qrImage = qr.createImgTag(10, 0);

  //creada para el valor del menu

 // const menuvalue = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu : 'undefined';
 //  //////console.log('chatvalue', state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu : 'undefined');





//loadLiveZillaScript(namespotyuser, true);


    return {
        isOwner: isPartyOwnerSelector(state),
        queueRoute: queueRouteSelector(state)!,
        settingsRoute: settingsRouteSelector(state)!,
        shareRoute: shareRouteSelector(state)!,
        subView: state.router.result!.subView,
        tvRoute: tvRouteSelector(state)!,
        userMenuOpen: state.partyView.userMenuOpen,
        username: username,
         namespotyuser: namespotyuser,
         partyidd: partyidd,
        shortidurl: shortidurl,
        //menuvalue: menuvalue,
        qrImage: qr.createImgTag(10, 0),
    };
};



const mapDispatchToProps: QueueDrawerDispatch = {
    enterAdmin: () => triggerOAuthLogin('spotify'),
    handleClick: handleLinkClick,
    logout,
    toggleUserMenu,
};

customElements.define('queue-drawer', connect(mapStateToProps, mapDispatchToProps)(QueueDrawer));