import '@polymer/paper-button/paper-button';
import '@polymer/paper-checkbox/paper-checkbox';
import '@polymer/paper-icon-button/paper-icon-button';
import '@polymer/paper-input/paper-input';
import '@polymer/paper-spinner/paper-spinner-lite';
import { connect } from 'fit-html';
import { html } from 'lit-html';
import { searchState   } from '../state';
import {
    queueRouteSelector,

} from '../selectors/routes';
import { triggerOAuthLogin } from '../actions/auth';
import {
    changePartyName,
    changePartyName2,
    changePartyName22,
    changePartySetting,
    changeSearchInput,
    flushQueueStart,
    insertPlaylistStart,
} from '../actions/view-party-settings';
import { filteredPlaylistsSelector } from '../selectors/playlists';
import { hasConnectedSpotifyAccountSelector } from '../selectors/users';
import { PartySettings, Playlist, State } from '../state';
import sharedStyles from '../util/shared-styles';

interface PartySettingsProps {
    isAuthorizing: boolean;
    isPlaylistLoadInProgress: boolean;
    isSpotifyConnected: boolean;
    partyName: string;
     partyName2: string;
     partyName22: string;
    
    playlists: Playlist[];
    playlistSearch: string;
    queueFlushInProgress: boolean;
    settings: PartySettings;
    tracksLoadInProgress: boolean;
    tracksToLoad: number;
    tracksLoaded: number;
}
////console.log("10 settings")
interface PartySettingsDispatch {
    changePartyName: (newName: string) => void;
      changePartyName2: (newName2: string) => void;
      changePartyName22: (newName22: string) => void;
    changePartySetting: <K extends keyof PartySettings>(setting: K, val: PartySettings[K]) => void;
    changeSearchInput: (newContent: string) => void;
    flushTracks: () => void;
    insert: (playlist: Playlist, shuffle: boolean) => void;
    triggerSpotifyLogin: () => void;
}

let extractedPath;
let globalPartyName2;
let shortURL ;
let base64 ;  // Variable global
let partyCode = "123";  // Variable global

function removeBase64Prefix(str) {
    return str.replace(/^data:image\/png;base64,/, "");
  }


// async function shortenLink(uno) {
//     if (uno === null || uno === undefined || uno === '') {
        
//         return null;
//       }
   
      
//      //me gusto pues da 1000 enlaces al mes y se pueden eliminar  
//   const url = 'https://api.short.io/links';
//   const options = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       authorization: 'sk_zPxSBkUOInqPMd0n',
//     },
//     body: JSON.stringify({
//         title: 'b467.short.gy',
//       originalURL: `http://player.microdelicos.co${uno}`,
//       domain: 'b467.short.gy'
//     })
//   };

//   try {
//     const response = await fetch(url, options);
//     const data = await response.json();
//     //console.log("Respuesta del servidor:",  data);
//     shortURL = data.shortURL; // Asignar a la variable global
//     //console.log("shortURL",shortURL);

//     // Llama a generateQRCode aquí
//    // await generateQRCode(shortURL);

//     return data.shortURL;
//   } catch (error) {
//     console.log("Error:", error);
//     return null;
//   }
// }


async function fetchData(uno, dos) {
   
    if (uno === null || uno === undefined || uno === '' ||
    dos === null || dos === undefined || dos === '') {
  return null;
}


    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow'
      };
  
    try {
      const response = await fetch(`http://localhost:3001/registro/${uno}/${dos}`, requestOptions);
      const result = await response.text();
      console.log("result",result);
    } catch (error) {
      console.log('registro', error);
    }
  }

  async function iaQrpro() {
    while (!globalPartyName2) {
        await new Promise(resolve => setTimeout(resolve, 1000));
    }
    
    function extractPathFromUrl(url) {
        return url.split('/').pop();
    }
    
    if (shortURL) {
        extractedPath = extractPathFromUrl(shortURL);
    }

    console.log("party id", globalPartyName2);
    console.log("codigo shrotlink", extractedPath);
    
    await fetchData(globalPartyName2, extractedPath);
}

// Llamar a la función



const LoginView = (props: PartySettingsProps & PartySettingsDispatch) => html``;

const PlaylistView = (props: PartySettingsProps & PartySettingsDispatch) => html``;

/* tslint:disable:max-line-length */
const SettingsView = (props: PartySettingsProps & PartySettingsDispatch) => html``;
/* tslint:enable */

const mapStateToProps = (state: State): PartySettingsProps => {
    // Asignar valor a la variable global
    globalPartyName2 = (state.party.currentParty || { short_id: '' }).short_id;

    // console.log(globalPartyName2);
    // console.log(queueRouteSelector(state));
    //shortenLink(queueRouteSelector(state));

    return {
        isAuthorizing: state.user.credentials.spotify.authorizing,
        isPlaylistLoadInProgress: state.settingsView.playlistLoadInProgress,
        isSpotifyConnected: hasConnectedSpotifyAccountSelector(state),
        partyName: (state.party.currentParty || { name: '' }).name,
        partyName2: globalPartyName2,  // Usar la variable global aquí
       
        partyName22: (state.party.currentParty || { short_id22: '' }).short_id22,
        playlists: filteredPlaylistsSelector(state),
        playlistSearch: state.settingsView.playlistSearchQuery,
        queueFlushInProgress: state.settingsView.queueFlushInProgress,
        settings: PartySettings.defaultSettings(
            state.party.currentParty && state.party.currentParty.settings,
        ),
        tracksLoadInProgress: state.settingsView.tracksLoadInProgress,
        tracksLoaded: state.settingsView.tracksLoaded,
        tracksToLoad: state.settingsView.tracksToLoad,
    };
};

const mapDispatchToProps: PartySettingsDispatch = {
    changePartyName,
    changePartyName2,
     changePartyName22,
    changePartySetting,
    changeSearchInput,
    flushTracks: flushQueueStart,
    insert: insertPlaylistStart,
    triggerSpotifyLogin: () => triggerOAuthLogin('spotify'),
};

customElements.define('party-settings', connect(mapStateToProps, mapDispatchToProps)(SettingsView));
