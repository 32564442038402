import 'dom-flip';
import { connect } from 'fit-html';
import 'ken-burns-carousel';
import { html, render } from 'lit-html';
import { createSelector } from 'reselect';
 import swal from 'sweetalert2';
 import ColorThief from 'colorthief';

//import QRCode from 'qrcode-generator';
import QRCode from 'qrcode';


let tituloAi  // Variable global
let descripcionAi
let shortURL ;
let base64 ;
let Ruta;
let iart ;  // Variable global
let partyCode ;  // Variable global
let QRpartyCode ;  // Variable global
let ScreenId ;  // Variable global
let ScreenId2 ;  // Variable global
let globalQRCodeData ;  // Variable global
let globalQRCodeData2 = null;  // Variable global
let globalResponse = null;
let dynamicId = 'qri';


function removeBase64Prefix(str) {
    return str.replace(/^data:image\/png;base64,/, "");
  }




async function shortenLink(uno) {
  if (uno === null || uno === undefined || uno === '') {
    return null;
  }
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ uno: uno });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow' as RequestRedirect
  };

  try {
    const response = await fetch('https://servicio-acortadorurls.vercel.app/shortenLink', requestOptions);
    
    const data = await response.json();
    //console.log("Error parsing JSwqwqwqwON:", data);

    try {
      await generateQRCode(data.shortURL);
      shortURL = data.shortURL;
      return shortURL;
    } catch (error) {
      console.log("Error parsing JSON:", error);
      return null;
    }
    
  } catch (error) {
    console.log("Error:", error);
    return null;
  }
}

//agrega poputmaster con pantalla de usuario
async function ScreenIdreader(uno) {
    if (uno === null || uno === undefined || uno === '') {
      return null;
    }
    
    const scriptPersonalizado = document.createElement('script');
    scriptPersonalizado.src = `https://livezilla-poptmaster.cgqo9s.easypanel.host/vistas/realtime.php?host=https://livezilla-poptmaster.cgqo9s.easypanel.host/&token=ur854MA4VfdcnMg&pantalla=${uno}`;
    document.body.appendChild(scriptPersonalizado);
    
console.log("cargado333333333333333333333",uno)
  }







  async function fetchDatadownlad(uno) {
    

    if (uno === null || uno === undefined || uno === '') {
  return null;
}
//console.log("uno",uno );
    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow'
      };
  


     //servicio websokets para almacenar las imagenes que se deben mostarr en el modo iaqr 
    try {
      const response = await fetch(`https://pgadmin-websoket.cgqo9s.easypanel.host/descarga/${uno}`, requestOptions);
      const result = await response.json();
      console.log("Respuesta  Servicio ws",result);
     console.log("Id de usuario a descargar servicio WS",uno);

      

      const originalString = result.data.data;
      const newString = removeBase64Prefix(originalString);
      //este se descomenta para dejarlo de respaldo
   
      base64 = newString;
      //console.log("base6422",base64);


    } catch (error) {
      console.log('Error en endpoint base64', error);
      
     base64 = globalQRCodeData;

     //console.log('Error en endpoint base642323', base64);
    //  setTimeout(async () => {
    //   await fetchDatadownlad(QRpartyCode);
    // }, 4000);
    }
  }

  















// async function main() {
//     try {
//       //await shortenLink(''); // Aquí puedes pasar la ruta que quieras
//       // La siguiente línea ya no es necesaria
//       // await generateQRCode(shortURL);
     
//     } catch (err) {
//       console.log("Error:", err);
//     }
//   }
  
  //genera el base64 para la miniatura se peude usar tambien para la otra vuelta 
  async function generateQRCode(shortURL) {
    
    return new Promise((resolve, reject) => {
       // console.log("shortURLqr",shortURL)
      QRCode.toDataURL(shortURL, { type: 'png', width: 890 }, function (err, url) {
        if (err) {
          reject(err);
          return;
        }
        const originalString = url;
        const newString = removeBase64Prefix(originalString);
        //este se descomenta para dejarlo de respaldo
        globalQRCodeData = newString;
        //partyCode = newString;
        //console.log(globalQRCodeData)
        resolve(newString);
        
      });
    });
  }
  
  let contador = 0;

  async function fetchData1() {
    
    // es necesario cambiara base 64 para coger el del qrtool
    while (!base64 || !iart) {
        console.log("IR-ART",iart);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    await executeFetchRequest(base64);
    contador++;
    console.log("MOSTRANDO  QR", contador);
  }
  
  // Ejecutar inmediatamente
  let tiempoTotal = 20;
  let tiempoMilisegundos = tiempoTotal * 1000;
  
  let intervalId = setInterval(() => {
    console.info(`Init StableDiffusion / missing: ${tiempoTotal} seg`);
    tiempoTotal--;
    if (tiempoTotal < 0) {
      clearInterval(intervalId);
    }
  }, 1000);
  
  setTimeout(() => {
    fetchData1();
  }, tiempoMilisegundos);
  
  
  
  


  //funcion loop para llamar hasta que se cumpla 
  (async () => {


    //showSwalWithRandomEffect()

    let extractedPath;
    while (!partyCode) {
        // console.log("partyCode",partyCode)
      // Espera un poco antes de volver a comprobar
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  
    function extractPathFromUrl(url) {
      return url.split('/').pop();
    }
  
    if (shortURL) {
      extractedPath = extractPathFromUrl(shortURL);
    }

    //await fetchData(partyCode, extractedPath);

   
   // console.log('partyCode está inicializado y extractedPath es', partyCode, extractedPath);
    setTimeout(async () => {
        await fetchDatadownlad(QRpartyCode);
      }, 8000);
  })();
  
  (async () => {


    //showSwalWithRandomEffect()

    let extractedPath;
    while (!Ruta) {
        
      // Espera un poco antes de volver a comprobar
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
// Verifica si ya se ha mostrado el swal.fire() en el pasado



   
    
    shortenLink(Ruta);

  })();


  //llama funcion para agregar widget pantalla
  (async () => {

   
    while (!ScreenId || !ScreenId2) {
        
      // Espera un poco antes de volver a comprobar
      await new Promise(resolve => setTimeout(resolve, 1000));
    }



   console.log("pantalla",ScreenId)
    
ScreenIdreader(ScreenId);

  })();
  
// main();



//llama al servicio de stable difucion
async function executeFetchRequest(base64) {
  interface Prompt {
    titulo: string;
    descripcion: string;
    prompt: string;
    negative_prompt: string;
  }

  let promptsArray: Prompt[] = [];

  const response = await fetch('prompts.json');
  promptsArray = await response.json();

  const randomIndex = Math.floor(Math.random() * promptsArray.length);
  const selectedPrompt = promptsArray[randomIndex];

   tituloAi = selectedPrompt.titulo;
   descripcionAi = selectedPrompt.descripcion;

   console.log("descripcionAi",descripcionAi)

  if (base64 === undefined || base64 === null || base64 === '') {
    return;
  }

  const originalString = base64;
  const newString = removeBase64Prefix(originalString);
  base64 = newString;
  function generateSeed() {
    return Math.floor(Math.random() * 26423251312141);
  }

  const data = {
    "prompt": selectedPrompt.prompt,
    "negative_prompt": selectedPrompt.negative_prompt,
    "scheduler": "dpmpp_2m",
    "num_inference_steps": 25,
    "guidance_scale": 7.5,
    "control_scale": 1.8,
    "control_start": 0.19,
    "control_end": 1,
    "samples": 14545,
    "seed": generateSeed,
    "size": 1024,
    "base64": "true",
    "invert": "false",
    "image": newString
  };

  //stable
  const localServerUrl = 'https://stabledifuson.onrender.com/fetchData';

  try {
    const response = await fetch(localServerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const result = await response.json();
    let globalResponse = result.image;

    const event = new CustomEvent('fetchResponseReceived', { detail: result.image });
    document.dispatchEvent(event);
  } catch (error) {
    console.log("Error:", error);

let tiempoTotal = 60; //defaul 600
let tiempoMilisegundos = tiempoTotal * 1000;

let intervalId = setInterval(() => {
  console.error(`Error server retry: ${tiempoTotal} seg`);
  tiempoTotal--;
  if (tiempoTotal < 0) {
    clearInterval(intervalId);
  }
}, 1000);

setTimeout(async () => {
  console.log("rejections peticiones");
  await fetchData1();
}, tiempoMilisegundos);

  }
}






  

 

import srcsetImg from '../components/srcset-img';
import { domainSelector } from '../selectors/domain';
import {
    artistJoinerFactory,
    currentTrackIdSelector,
    queueTracksSelector,
    singleMetadataSelector,
    singleTrackSelector,
} from '../selectors/track';


import {
    queueRouteSelector,

} from '../selectors/routes';

import { Metadata, Party, State, Track } from '../state';
import theparcheplayerlogo from '../util/festify-logo';
import sharedStyles from '../util/shared-styles';

import './tv-track';

interface ViewTvProps {
    backgroundImgIndex: number | null;
    currentTrackArtistName: string | null;
    currentTrackMetadata: Metadata | null;
    text: string;
    hasTracks: boolean;
    initError: Error | null;
    isLoading: boolean;
    metadata: Record<string, Metadata>;
    party: Party | null;
    queueTracks: Track[];
     track: Track | null;
     menuvalue: boolean|string;
     queueRoute: string;
     shouldShowContent: boolean;
}








/* tslint:disable:max-line-length */
const Background = (props: ViewTvProps) => {
    if (!props.currentTrackMetadata) {
        throw new Error("Missing metadata");
    }

    if (props.currentTrackMetadata.background &&
        props.currentTrackMetadata.background.length > 0 &&
        props.backgroundImgIndex != null) {
        return html`
            <ken-burns-carousel .images=${props.currentTrackMetadata.background}>
            </ken-burns-carousel>
        `;
    } else {
        return html`
            <div class="background">
                ${srcsetImg(props.currentTrackMetadata.cover, '49vh')}
            </div>
        `;
    }
};

const Lower = (props: ViewTvProps) => {
    const list = props.queueTracks.map(t => html`
        <tv-track class="animated bounceInUp delay-1s faster" .trackid="${t.reference.provider}-${t.reference.id}"
                  data-flip-id="${t.reference.provider}-${t.reference.id}">
        </tv-track>
    `);

    return typeof window.ShadyCSS === 'object' && !window.ShadyCSS!.nativeShadow
        ? html`<div class="lower">${list}</div>`
        : html`<dom-flip class="lower">${list}</dom-flip>`;
};

const Body = (props: ViewTvProps) => {
    if (props.isLoading || (props.hasTracks && !props.currentTrackMetadata)) {
        return html`
            <div class="no-tracks">
                <div class="header">
                   
                    <h1>⚡⚡ ⚡ Loading ⚡⚡⚡</h1>
                </div>
            </div>
        `;
    } else if (props.initError) {
        return html`
            <div class="no-tracks">
                <div class="header">
                    <span class="flash">⚡️</span>
                    <h1>Oh, no!</h1>
                </div>
                <h2>Este parche no ha sido cargado correctamente . posiblemente sea por esto:</h2>
                <h2>${props.initError.message}</h2>
            </div>
        `;
    } else if (!props.hasTracks) {
        return html`
            <div class="no-tracks">
                <div class="header">
                   
                    <h1>😥</h1>
                </div>
                <h2>No hay canciones en el momento.</h2>
                
            </div>
        `;
    } else {
        return html`

            ${Background(props)}


            <div class="upper">

            <div class="playing-track2">

                   
                    <img class="ddd rotating" src="img/11.png" alt="album1" /></div>
                    
                <div class="playing-track">

                ${props.menuvalue === true && globalQRCodeData !== null && globalQRCodeData !== undefined ? html`
                <img id="${dynamicId}" class="ddd2 " src="data:image/png;base64,${globalQRCodeData}" alt="Código QR">
              ` : ''}
              
                 
                   
                    
                    
                
                    ${srcsetImg(props.currentTrackMetadata!.cover, '49vh')}

                    <div class="metadata">
                     <h2></h2>
                        <h2>${props.currentTrackMetadata!.name}</h2>
                        <h3>${props.currentTrackArtistName}</h3>

                        <playback-progress-bar></playback-progress-bar>

                        <h4><strong>Compartido Por:</strong> ${props.track ? props.track.saludo : 'Administrador'}</h4>
                        <h5>Código del Parche: ${props.party && props.party.short_id}</h5> 
                    </div>
                </div>
                </div>
                </div>
                </div>

                <div >
               ${Lower(props)}
            </div>
        `;
    }
};

const ViewTv = (props: ViewTvProps) => html`
    ${sharedStyles}
    <style>
        :host {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            position: absolute;
            top: 0; left: 0;
            bottom: 0; right: 0;
            overflow: hidden;
            font-size: 5.278vh;
        }

        :host(.no-cursor) {
            cursor: none;
        }

        .upper, .lower {
            font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
            display: flex;
        }

        .upper {
            flex-direction: column;
            justify-content: center;
            padding: 0 8.334vh;
            overflow: hidden;
            flex-grow: 1;
            width: 100%;
        }

        .lower {
            flex-direction: row;
            height: 29.26vh;
            padding-left: 8.334vh;
        }

        ken-burns-carousel, .background img {
            position: absolute;
            opacity: 0.3;
            width: 100%;
            height: 100%;
            --img-filter: blur(7px);
        }

        .background img {
            filter: blur(7px);
            object-fit: cover;
            transform: scale(1.02);
        }

        .playing-track {
            display: flex;
            align-items: center;
            z-index: 1;
        }

        .playing-track img {
            margin-right: 8.334vh;
            height: 49vh;
            width: 49vh;
            box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.5);
            border-radius: 20px;
        }

        .playing-track2 {
            display: flex;
            align-items: center;
            z-index: 1;
        }

     .playing-track2 img {
    /* margin-right: 8.334vh; */
    /* height: 46vh; */
    width: 48vh;
}

        .metadata {
            flex-grow: 1;
            overflow-x: hidden;
        }

playback-progress-bar {
    margin: 4.167vh 0;
    height: 1.556vh;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
}

        tv-track {
            --max-width: 18.889vh;
            margin-right: 4.815vh;
        }

        h1, h2, h3, h4, h5 {
            margin: 0;
            opacity: 0.9;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .upper h2 {
            font-size: 5.278vh;
            font-weight: normal;
            line-height: 7.407vh;
        }

        .upper h3 {
            font-size: 5.278vh;
            font-weight: 100;
            line-height: 7.407vh;
        }

        .upper h4 {
            font-size: 4.444vh;
            font-weight: normal;
            line-height: 5.185vh;
            margin-bottom: 2.4vh;
        }

        .upper h5 {
            font-size: 4.444vh;
            font-weight: 200;
            line-height: 4.444vh;
            padding: 0.92vh 1.39vh;
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
            border-radius: 0.74vh;
            display: inline-block;
        }

        .no-tracks {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            height: 100%;
            text-align: center;
        }

        .no-tracks .header {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-bottom: 8vh;
        }

        .no-tracks .flash {
            font-size: 12vh;
        }



        .no-tracks svg {
            height: 16vh;
        }

        .no-tracks h1, .no-tracks h2 {
            font-weight: normal;
        }

        .no-tracks h1 {
            color: white;
            font-size: 7vh;
            margin-left: 32px;
        }

.ddd {

    width: 291px;
    height: 300px;
    position: absolute;
    height: auto;
    left: 33vh;
    right: 10%;
    bottom: 41%;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    /* position: relative; */
    /* max-width: 92%; */
    /* box-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3); */
    /* -webkit-transform: translate3d(0,0,0); */
    /* transform: translate3d(0,0,0); */
}


.ddd2 {
  border-radius: 10px;
    width: 80px;
    height: 300px;
    position: absolute;
    height: auto;
    left: 23px;
    /* right: 10%; */
    bottom: 41%;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    /* position: relative; */
    /* max-width: 92%; */
    /* box-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3); */
    /* -webkit-transform: translate3d(0,0,0); */
    /* transform: translate3d(0,0,0); */
    z-index: 1;

   
    animation-name: cambiar-ancho;
    animation-duration: 180s; /* 3 minutos */
    animation-iteration-count: infinite;
}

@keyframes cambiar-ancho {
    0% {
        width: 49vh;
        height: 49vh;
        opacity: 0;
        
    }

    75% {
        width: 49vh; /* de 102 a 201 (5 segundos) */
        height: 49vh;
        opacity: 0;

    }
    
    79% {
      width: 49vh; /* de 102 a 201 (5 segundos) */
      height: 49vh;
      opacity: 0.2;

  }
    80% {
        width: 49vh; /* de 201 a 200 (45 segundos) */
        height: 49vh;
        opacity: 1;
    }
    98% {
        width: 49vh; /* de 201 a 200 (45 segundos) */
        height: 49vh;
        opacity: 1;
    }

    100% {
        width: 49vh; /* de 200 a 101 (5 segundos) */
        height: 49vh;
        opacity: 0;
    }
}


.headerr {
    width: 291px;
    height: 300px;
    position: absolute;
    height: auto;
    left: 207px;
    right: 10%;
    bottom: 41%;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    /* position: relative; */
    /* max-width: 92%; */
    /* box-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3); */
    /* -webkit-transform: translate3d(0,0,0); */
    /* transform: translate3d(0,0,0); */
      display: flex;
  justify-content: center;
  align-items: center;

}
        .no-tracks h2 {
            font-size: 5.278vh;
        }

        @-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
    </style>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css">



    ${Body(props)}
`;
/* tslint:enable */





 
const artistNameSelector = artistJoinerFactory();
const hasTracksSelector = createSelector(
    queueTracksSelector,
    tracks => !!tracks.length,
);
const restTracksSelector = createSelector(
    queueTracksSelector,
    (tracks: Track[]) => tracks.slice(1, 30),
);


const mapStateToProps = (state: State): ViewTvProps => {
    const currentTrackId = currentTrackIdSelector(state);
    const meta = currentTrackId
        ? singleMetadataSelector(state, currentTrackId)
        : null;


    const namespotyuser = state.party.currentParty
    ? state.party.currentParty.short_id

    : '';

ScreenId = state.party.currentParty ? state.party.currentParty.tvscreenid : '';

QRpartyCode = state.party.currentParty ? state.party.currentParty.short_idQR : '';

partyCode = state.party.currentParty ? state.party.currentParty.short_id : '';
iart = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu5 : '';
//console.log("namespotyuser",partyCode)
// Resto del código
//utilizo party code para hacer un loop y solo llamar una sola vez la funcion que necesito que es la de abriri crome en esta ocacion

    Ruta=queueRouteSelector(state);



 const menuvalue = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu : 'undefined';


 ScreenId2 = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu6 : 'undefined';
 
//  console.log(ScreenId2);




    return {
        // Choose background image to display based on track name
        backgroundImgIndex: meta && meta.background && meta.background.length > 0
            ? meta.name.length % meta.background.length
            : null,
        currentTrackArtistName: currentTrackId
            ? artistNameSelector(state, currentTrackId)
            : null,
         track: currentTrackId
            ? singleTrackSelector(state, currentTrackId)
            : null,
    
        currentTrackMetadata: meta,
        hasTracks: hasTracksSelector(state),
        initError: state.party.partyLoadError,
        isLoading: state.party.partyLoadInProgress || !state.party.hasTracksLoaded,
        metadata: state.metadata,
        party: state.party.currentParty,
        text: state.party.currentParty &&
            state.party.currentParty.settings &&
            state.party.currentParty.settings.tv_mode_text ||
            `Tu eres el DJ de este Parche`,
        queueTracks: restTracksSelector(state),
          //track: singleTrackSelector(state, currentTrackId),
        menuvalue: menuvalue,
         queueRoute: queueRouteSelector(state)!,
          shouldShowContent: false,

    };
};

class TvMode extends connect(mapStateToProps, {})(ViewTv) {
    private boundMouseMoveHandler: () => void;
    private mouseTimeout: any;

    constructor() {
        super();
           // Escuchar el evento personalizado

   

    document.addEventListener('fetchResponseReceived', this.handleFetchResponse);
    //document.addEventListener('fetchResponseReceived2', this.handleFetchResponse2);

    this.boundMouseMoveHandler = () => this.onMouseMove();

        
    }


    
handleFetchResponse = (event) => {
    const response = event.detail;
     
    console.log("Detalles del evento:", event.detail);

    // Verificar si la respuesta contiene datos
    if (response && Object.keys(response).length > 0) {
        // Convertir el objeto de respuesta a un array
       

        // Mostrar SweetAlert con la imagen, agrgar fondo mas oscuro 
        

     
    const colorThief = new ColorThief();
    const img = new Image();
    let gradientColor;
    
    img.crossOrigin = "anonymous";
        img.src = `data:image/png;base64,${event.detail}`;
 
        
        img.onload = function() {
            try {
                const dominantColor = colorThief.getColor(img);
                const palette = colorThief.getPalette(img, 5);
        
                const gradientColors = palette.map((color, index) => {
                    const opacity = index === 0 ? 0.71 : index === 1 ? 1 : 0.48;
                    const position = index === 0 ? "0%" : index === 1 ? "52%" : "87%";
                    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity}) ${position}`;
                }).join(", ");
                
                const gradientColor = `linear-gradient(45deg, rgba(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]}, 1) 0%, rgba(${palette[0][0]}, ${palette[0][1]}, ${palette[0][2]}, 0.71) 25%, rgba(${palette[1][0]}, ${palette[1][1]}, ${palette[1][2]}, 1) 50%, rgba(${palette[2][0]}, ${palette[2][1]}, ${palette[2][2]}, 0.71) 75%, rgba(${palette[3][0]}, ${palette[3][1]}, ${palette[3][2]}, 1) 100%)`;

                // const gradientColor = `linear-gradient(45deg, rgba(${palette[3][0]}, ${palette[3][1]}, ${palette[3][2]}, 1) 0%, rgba(${palette[2][0]}, ${palette[2][1]}, ${palette[2][2]}, 0.8) 25%, rgba(${palette[1][0]}, ${palette[1][1]}, ${palette[1][2]}, 0.6) 50%, rgba(${palette[0][0]}, ${palette[0][1]}, ${palette[0][2]}, 0.4) 75%, rgba(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]}, 0) 100%)`

                let effects = [
                    'bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp',
                    'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight', 
                    'fadeInRightBig', 'fadeInUp', 'fadeInUpBig',
                    'flipInX', 'flipInY',
                    
                    'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight',
                    'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp',
                    'slideInDown',   ,
                    'jackInTheBox', 'rollIn'
                  ];
                      let currentIndex = 0;
                      
                      function showSwalWithRandomEffect() {
                          let effect = effects[Math.floor(Math.random() * effects.length)];
                        
                          swal.fire({
                            title: tituloAi,
                            //text: descripcionAi,
                            position: "center",
                            //background: "rgba(0, 121, 255, 0)",
                            showConfirmButton: false,
                            html: `${descripcionAi}<style>.ddd2 {
                           
                            
                              animation: fade-in-out 40s ease-in-out forwards;
                                //animation-iteration-count: infinite;
                            }
                            
                            @keyframes fade-in-out {
                              0%, 12.5% {
                                opacity: 0;
                                -webkit-transform: translate3d(0,100%,0);
                                transform: translate3d(0,100%,0)
                              }
                              15%, 97.5% {
                                opacity: 1;
                                -webkit-transform: translateZ(0);
                                transform: translateZ(0)
                              }
                               99% {
                                opacity: 1;
                                -webkit-transform: translate3d(0,-100%,0);
                                transform: translate3d(0,-100%,0)
                              }
                              100% {
                                opacity: 0;
                                -webkit-transform: translateZ(0);
        transform: translateZ(0)
                              }
                            }
                          }</style>
                              
                              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>        
                              <img class="animate__animated animate__${effect} animate__delay-4s" src="data:image/png;base64,${event.detail}" alt="Imagen" style="height:auto; position:absolute; width:622px; top:-290px; right:-102px; border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">

                              <img class="ddd2  " src="https://res.cloudinary.com/dze3qklw3/image/upload/v1693961165/Sin_t%C3%ADtulo-1_xrvasu.png" alt="Imagen" style="height:auto; position:absolute; width:81px; top:305px; left: 0px; ">
                            `,
                            backdrop: `
                              ${gradientColor},
                              url("https://i.blogs.es/09c898/sd1/1366_20001.jpeg")
                              no-repeat fixed center -35px / contain
                            `,
                          });
                          
                currentIndex = (currentIndex + 1) % effects.length;
            }
            showSwalWithRandomEffect()



            } catch (error) {
                console.log("Ha ocurrido un error:", error);
            }
        };
        
        img.onerror = function() {
            console.log("Error al cargar la imagen.");
        };
        
       
        

        let timeLeft = 60; //def 120 segundos

        // Primer contador
        const intervalId = setInterval(() => {
          if (timeLeft <= 0) {
            clearInterval(intervalId);
            primeraAccion();
          }
          console.log(`Faltan ${timeLeft} segundos.`);
          timeLeft--;
        }, 1000);
        
        // Primera acción
        async function primeraAccion() {
          console.log("Deteniendo contador y cerrando SW");
          swal.close();
          console.log("Dowload mikroqr");
          await fetchDatadownlad(QRpartyCode);
          iniciarFetchData1YContador();
        }
        
        // Inicia contador y ejecución de fetchData1
        function iniciarFetchData1YContador() {
          ejecutarFetchData1YContador(); // Primera ejecución y primer contador
          //setInterval(ejecutarFetchData1YContador, 180000); // Repite cada 3 minutos
        }
        
        // Ejecuta fetchData1 y su contador
        async function ejecutarFetchData1YContador() {
          let countdown = 60; //def 300
          const fetchData1Countdown = setInterval(async () => {
            console.log(`Faltan ${countdown} segundos para iA ART.`);
            countdown--;
            if (countdown < 0) {
              clearInterval(fetchData1Countdown);
              await fetchDatadownlad(QRpartyCode);
              await new Promise(resolve => setTimeout(resolve, 1000));
              console.log("fetch ST")
              await fetchData1();
              
            }
          }, 1000);
        }
        
        
        
          

        

    } else {
        // Mostrar SweetAlert de error si la respuesta está vacía o no es válida
        swal.fire("Error", "La respuesta está vacía o no es válida", "error");
    }
}



    
    connectedCallback() {
        super.connectedCallback();

        this.addEventListener('mousemove', this.boundMouseMoveHandler);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
          // Remover el evento
    document.removeEventListener('fetchResponseReceived', this.handleFetchResponse);
    // document.removeEventListener('fetchResponseReceived2', this.handleFetchResponse2);
    this.removeEventListener('mousemove', this.boundMouseMoveHandler);

        this.removeEventListener('mousemove', this.boundMouseMoveHandler);
    }

    onMouseMove() {
        this.classList.remove('no-cursor');
        if (this.mouseTimeout) {
            clearTimeout(this.mouseTimeout);
        }
        this.mouseTimeout = setTimeout(() => this.classList.add('no-cursor'), 3000);
    }
}


customElements.define('view-tv', TvMode);
