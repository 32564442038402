
import '@polymer/paper-button/paper-button';
import '@polymer/paper-input/paper-input';
import '@polymer/polymer/lib/elements/custom-style';
import { connect } from 'fit-html';
import { html } from 'lit-html';

import { triggerOAuthLogin } from '../actions/auth';
import { createPartyStart, joinPartyStart as joinParty } from '../actions/party-data';
import { changePartyId } from '../actions/view-home';
import { State } from '../state';
import theparcheplayerlogo from '../util/festify-logo';
import sharedStyles from '../util/shared-styles';

interface HomeViewProps {
    authorizationInProgress: boolean;
    authorizedAndPremium: boolean;
    authStatusKnown: boolean;
    partyCreationInProgress: boolean;
    partyCreationError: Error | null;
    partyId: string;
    partyIdValid: boolean;
    partyJoinError: Error | null;
    partyJoinInProgress: boolean;
    playerCompatible: boolean;
}
interface HomeViewDispatch {
    changePartyId: (partyId: string) => void;
    createParty: () => void;
    joinParty: () => void;
    loginWithSpotify: () => void;
}
////console.log("2 home")
// const LowerButton = (props: HomeViewProps & HomeViewDispatch) => {
//     if (props.partyCreationInProgress) {
//         return html`
//             <paper-button raised disabled>
//                 Creando parche...
//             </paper-button>
//         `;
//     } else if (props.authorizedAndPremium) {
//         return html`
//             <paper-button raised @click=${props.createParty}>
//                 Crear Parche
//             </paper-button>
//         `;
//     } else if (props.authorizationInProgress || !props.authStatusKnown) {
//         return html`
//             <paper-button raised disabled>
//                 Verficando...
//             </paper-button>
//         `;
//     } else {
//         return html`
//            <paper-button raised @click=${props.loginWithSpotify}>
//                 Crear Parche
//             </paper-button>
//         `;
//     }
// };


const HomeView = (props: HomeViewProps & HomeViewDispatch) => html`
    ${sharedStyles}
<style>

 .contenedor23  {
  position: absolute;
    top: 46%;
}


:root {
     --color-background: #31037d;
     --axis-x: 1px;
     --axis-y: 1rem;
     --delay: 10;
     --color-black: #000;
     --color-white: #fff;
     --color-orange: #d49c3d;
     --color-red: #d14b3d;
     --color-violet: #cf52eb;
     --color-blue: #44a3f7;
     --color-green: #5acb3c;
     --color-yellow: #debf40;
     --color-foreground: var(--color-white);
     --font-name: Righteous;
}
 html, body {
     font-size: 24px;
}

 .c-rainbow {
     counter-reset: rainbow;
     position: relative;
     display: block;
     list-style: none;
     padding: 0;
     margin: 0;
}
.c-rainbow__layer {
    --text-color: var(--color-foreground);
    counter-increment: rainbow;
    font-size: 4rem;
    color: var(--text-color);
    text-shadow: -1px -1px 0 var(--color-black), 1px -1px 0 var(--color-black), -1px 1px 0 var(--color-black), 1px 1px 0 var(--color-black), 4px 4px 0 rgba(0, 0, 0, .2);
    animation: rainbow 1.5s ease-in-out infinite;
}
 .c-rainbow__layer:nth-child(1) {
     animation-delay: calc(1 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 1);
     z-index: -10;
}
 .c-rainbow__layer:nth-child(2) {
     animation-delay: calc(2 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 2);
     z-index: -20;
}
 .c-rainbow__layer:nth-child(3) {
     animation-delay: calc(3 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 3);
     z-index: -30;
}
 .c-rainbow__layer:nth-child(4) {
     animation-delay: calc(4 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 4);
     z-index: -40;
}
 .c-rainbow__layer:nth-child(5) {
     animation-delay: calc(5 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 5);
     z-index: -50;
}
 .c-rainbow__layer:nth-child(6) {
     animation-delay: calc(6 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 6);
     z-index: -60;
}
 .c-rainbow__layer:nth-child(7) {
     animation-delay: calc(7 / var(--delay) * 1s);
     left: calc(var(--axis-x) * 7);
     z-index: -70;
}
 .c-rainbow__layer:not(:first-child) {
     position: absolute;
     top: 0;
}
 .c-rainbow__layer--white {
     --text-color: var(--color-white);
}
 .c-rainbow__layer--orange {
     --text-color: var(--color-orange);
}
 .c-rainbow__layer--red {
     --text-color: var(--color-red);
}
 .c-rainbow__layer--violet {
     --text-color: var(--color-violet);
}
 .c-rainbow__layer--blue {
     --text-color: var(--color-blue);
}
 .c-rainbow__layer--green {
     --text-color: var(--color-green);
}
 .c-rainbow__layer--yellow {
     --text-color: var(--color-yellow);
}
 @keyframes rainbow {
     0%, 100% {
         transform: translatey(var(--axis-y));
    }
     50% {
         transform: translatey(calc(var(--axis-y) * -1));
    }
}

.content__title {
    font-family: 'ferrite_coreregular';
}
 
</style>

    <style>
        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0 10px;
            text-align: center;

            background:
            /* ejemplo del api de imagenes  https://awik.io/generate-random-images-unsplash-without-using-api/ 
url(https://source.unsplash.com/1600x900/?antioquia) no-repeat center;
            */
           
                linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)),
                url(https://source.unsplash.com/collection/21662092/1600x900) no-repeat center;
            background-size: cover;
            display: flex;
            flex-flow: column nowrap;
            height: 176px;
            position: relative;

            --paper-input-container-input: {
                font-size: 24px;
            }

            --paper-input-container-label: {
                font-size: 20px;
            }
        }

        paper-button[disabled] {
            opacity: 0.8;
        }

        svg {
            height: 180px;
            width: 180px;
        }

        p {
            padding: 0 25px;
            max-width: 500px;
            font-size: 20px;
        }

        main {
            display: flex;
            flex-flow: column nowrap;

            margin: 0 auto;
            min-width: 250px;
        }

        #middle {
            margin: 8px 0 16px 0;
        }
.ddd {
    width: 236px;
    height: 300px;
    position: relative;
    height: auto;
    /* left: 11%; */
    /* right: 10%; */
    /* bottom: 41%; */
    /* -webkit-transform: translate3d(10%, 0, 0); */
    /* transform: translate3d(10%, 0, 0); */
    /* -webkit-transition: -webkit-transform 0.3s; */
    /* transition: -webkit-transform 0.3s; */
    /* transition: transform 0.3s; */
    /* transition: transform 0.3s, -webkit-transform 0.3s; */
    /* position: relative; */
    /* max-width: 92%; */
    /* box-shadow: 2px 16px 26px 0px rgb(36 33 69 / 30%); */
    /* -webkit-transform: translate3d(0,0,0); */
    /* transform: translate3d(0,0,0); */
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.contenedor2 {
    /* width: fit-content; */
    /* height: fit-content; */
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    float: left;
}

      .ddd2 {
    width: 316px;
    height: 300px;
    /* position: absolute; */
    height: auto;
    /* left: 11%; */
    /* right: 10%; */
    /* bottom: 41%; */
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    /* position: relative; */
    /* max-width: 92%; */
    /* box-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3); */
    /* -webkit-transform: translate3d(0,0,0); */
    /* transform: translate3d(0,0,0); */


}

.contenedor3 {
    width: fit-content;
    /* height: fit-content; */
    position: absolute;
    left: 50%;
    top: 41%;
    transform: translate(-50%, -50%);
    float: left;
}

@media (max-width: 500px) {

.contenedor3 {
    /* width: fit-content; */
    /* height: fit-content; */
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    float: left;
}
  /* acá van las reglas CSS que aplican para este media query */
}
    </style>

    <header>
       
<div class="contenedor2 animated zoomInDown delay-2s slow">              
<img class="ddd rotating" src="img/11.png" alt="Logotipo de HTML5" width="400" height="453">
</div>

    <div class="contenedor3 animated zoomInUp delay-2s slow content2 ">
    
<ul class="c-rainbow content__title ">
 <li class="c-rainbow__layer c-rainbow__layer--white">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--orange">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--red">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--violet">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--blue">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--green">MIKRODELICOS</li>
 <li class="c-rainbow__layer c-rainbow__layer--yellow">MIKRODELICOS</li>
</ul> 
</div>

    </header>
</div>

    </header>





<div class="contenedor23 animated zoomInDown delay-2s slow">
    <p>Entre Notas y Bytes</p>
</div>


    <main>
<paper-input label="ID Parche"
             type="tel"
             @input=${ev => {
                 const inputValue = (ev.target as HTMLInputElement).value;
                 props.changePartyId(inputValue);
                 if (inputValue.length >= 4 && props.partyIdValid) {
                     props.joinParty();
                 }
             }}
             value="${props.partyId}">
</paper-input>

<paper-button id="middle"
              raised
              ?disabled=${!props.partyIdValid}
              @click=${props.joinParty}>
    ${props.partyJoinInProgress ? "Ingresando..." : "Ingresar"}
</paper-button>




       
    </main>
`;
/* tslint:enable */

const mapStateToProps = (state: State): HomeViewProps => ({
    ...state.homeView,
    authorizationInProgress: state.user.credentials.spotify.authorizing,
    authorizedAndPremium: Boolean(
        state.user.credentials.spotify.user &&
        state.user.credentials.spotify.user.product === 'premium',
    ),
    authStatusKnown: state.user.credentials.spotify.statusKnown,
    playerCompatible: state.player.isCompatible,
});

const mapDispatchToProps: HomeViewDispatch = {
    changePartyId,
    createParty: createPartyStart,
    joinParty,
    loginWithSpotify: () => triggerOAuthLogin('spotify'),
};

customElements.define(
    'view-home',
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(HomeView),
);
